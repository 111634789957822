<template>
  <div class="" id="notice">
    <div class="container-fluid">

      <div class="row" v-for="(notice,key) in notices" :key="key">
        <div class="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column bd-highlight pt-3 pb-3">

          <div class="p-2 w-100 bd-highlight bg-success basic-info rounded mb-sm-3 mb-md-0">
            <div class="card text-white bg-success mb-3 border-0" style="max-width: 100%;">
              <div class="card-header"><h4 class="card-title bf-1">{{notice.bn_title}}</h4></div>
              <div class="card-body">
                <p class="bf-1 text-start" style="text-align: justify !important;" v-html="notice.bn_description"></p>
<!--                <p class="bf-1 text-start" style="text-align: justify !important;" v-html="notice.bn_description"></p>-->
              </div>
            </div>
          </div>

<!--          <div class="p-2 w-100 bd-highlight bg-danger basic-info rounded">-->
<!--            <div class="card text-white bg-danger mb-3 border-0" style="max-width: 100%; font-family: Rockwell;">-->
<!--              <div class="card-header"><h5 class="card-title">{{notice.en_title}}</h5></div>-->
<!--              <div class="card-body">-->
<!--                <p class="text-start" style="text-align: justify !important;">{{notice.en_description}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "Notice",
    components:{},
    data(){
      return {}
    },
    beforeMount() {},
    created(){},
    updated() {},
    computed:{
      notices(){return this.$store.getters.getNotices;}
    },
  }
</script>

<style scoped>

</style>